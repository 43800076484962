import styled from "styled-components";
import { mediaCSS } from "../../../../theme/CSSPattern";

const CardWrapperSC = styled("div")`
  max-height: 278px;
  height: 100%;
  width: 97%;
  max-width: 580px;
  display: flex;
  background: #000000;
  margin-bottom: 40px;
  border-radius: 15px;
  overflow: hidden;
  ${mediaCSS(996)} {
    margin-bottom: 4px;
    flex-direction: column;
  }
`;

const RightWrapperSC = styled("div")`
  padding: 11px 20px 24px 30px;
  max-width: 53%;
  ${mediaCSS(1300)} {
    paddin: 11px 10px 24px 30px;
  }
  ${mediaCSS(996)} {
    max-width: 100%;
    padding: 9px 3px 16px 8px;
  }
`;

const PriceSC = styled("h4")`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  margin-bottom: 2px;
  ${mediaCSS(996)} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }
`;
const NumberSC = styled("p")`
  word-break: break-word;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #8c8c8c;
  margin-bottom: 2px;
  overflow: hidden;
  height: 50px;
  text-overflow: clip;
  ${mediaCSS(1150)} {
    font-size: 14px;
  }
  ${mediaCSS(996)} {
    line-height: 19px;
    height: 38px;
  }
  ${mediaCSS(600)} {
    margin-bottom: 0;
  }
`;
const ImgSC = styled("img")`
  object-fit: cover;
  max-width: 47%;
  width: 100%;
  height: 100%;
  ${mediaCSS(996)} {
    max-width: 100%;
    max-height: 115px;
  }
`;
const PlaceholderWrapperSC = styled("div")`
  position: relative;
  background: #737373;
  max-width: 47%;
  width: 100%;
  height: 100%;
`;
const PartNameSC = styled("h3")`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  min-height: 54px;
  margin-bottom: 7px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
  ${mediaCSS(996)} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 9px;
    min-height: 44px;
  }
`;

const DescriptionSC = styled("p")`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${mediaCSS(996)} {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const useProductCardStyle = () => ({
  ImgSC,
  PriceSC,
  NumberSC,
  PartNameSC,
  DescriptionSC,
  CardWrapperSC,
  RightWrapperSC,
  PlaceholderWrapperSC,
});
