import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

const BGSC = styled("section")`
  background: url(/geersBG.jpg);
  background-size: 100% 100%;
  padding: 49px 20px 55px 20px;
  ${mediaCSS(996)} {
    padding: 16px 22px 23px 22px;
  }
`;

const WrapperSC = styled("div")`
  background: ${({ theme }) => theme.aboutCompany};
  border-radius: 15px;
  padding: 17px 70px 36px 52px;
  margin: 0 auto;
  max-width: 1567px;
  width: 100%;
  ${mediaCSS(996)} {
    padding: 15px 23px 24px 24px;
  }
`;

const TitleSC = styled("h1")`
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  text-align: right;
  margin-bottom: 3px;
  ${mediaCSS(600)} {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 11px;
  }
`;

const DescrSC = styled("p")`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: #ffffff;
  max-width: 1444px;
  margin: 0 0 0 auto;
  ${mediaCSS(996)} {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
  }
`;

export const useAboutCompanyStyle = () => {
  return { BGSC, TitleSC, DescrSC, WrapperSC };
};
