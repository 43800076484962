import CardFull from "components/CardFull/CardFull";
import React from "react";
import AppContainer from "../anyPage/AppContainer/AppContainer";
import FormFeedback from "./FormFeedback/FormFeedback";
import ProductList from "./ProductList/ProductList";
import { useBottomBlockStyle } from "./style";

interface IBottomBlock {
  isViewCard: boolean;
}

const BottomBlock = ({ isViewCard }: IBottomBlock) => {
  return (
    <WrapperSC>
      <AppContainer maxWidth={1650}>
        <ContentWrapperSC>
          <FormFeedback />
          {isViewCard ? <ProductList /> : <CardFull />}
        </ContentWrapperSC>
      </AppContainer>
    </WrapperSC>
  );
};

const { WrapperSC, ContentWrapperSC } = useBottomBlockStyle();

export default React.memo(BottomBlock);
