import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

const WrapperSC = styled("section")`
  background: ${({ theme }) => theme.mainBg};
  padding: 35px 0 0 62px;
  ${mediaCSS(996)} {
    padding: 21px 9px 31px 9px;
  }
`;
const ContentWrapperSC = styled("div")`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  ${mediaCSS(996)} {
    flex-direction: column-reverse;
  }
`;

export const useBottomBlockStyle = () => ({
  WrapperSC,
  ContentWrapperSC,
});
