import React from "react";
import { Controller, FormProvider } from "react-hook-form";
import { ButtonUI } from "../../../UI/ButtonUI/style";
import TitleUI from "../../../UI/TitleUI/TitleUI";
import EmailInput from "./EmailInput/EmailInput";
import FullNameInput from "./FullNameInput/FullNameInput";
import PhoneInput from "./PhoneInput/PhoneInput";
import { useFormFeedbackStyle } from "./style";
import TextArea from "./TextArea/TextArea";
import { useFormFeedback } from "./useFormFeedback";

const FormFeedback = () => {
  const { method, isError, isMobile, control, onSubmit, handleSubmit } =
    useFormFeedback();
  return (
    <>
      <FormProvider {...method}>
        <FormSC onSubmit={handleSubmit(onSubmit)}>
          <FormTitleWrapperSC>
            <TitleUI
              text="Обратная связь"
              textAlign={isMobile ? "left" : "center"}
              styleComponents={{ TitleStyle: FormTitleSC }}
            />
          </FormTitleWrapperSC>
          <TextArea />
          <FullNameInput />
          <EmailInput />
          <PhoneInput />
          <ButtonUI type="submit">Отправить</ButtonUI>
          {isError && (
            <ErrorMessageSC>
              Что-то пошло не так, попробуйте снова...
            </ErrorMessageSC>
          )}
        </FormSC>
      </FormProvider>
    </>
  );
};

const {
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  FormTitleWrapperSC,
  RequisitesTitleWrapperSC,
} = useFormFeedbackStyle();

export default React.memo(FormFeedback);
