import BottomBlock from "components/BottomBlock/BottomBlock";
import MainLayout from "layout/mainLayout";
import React from "react";

const HomePage = () => {
  return (
    <MainLayout>
      <BottomBlock isViewCard={true} />
    </MainLayout>
  );
};

export default React.memo(HomePage);
