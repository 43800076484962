import React from "react";
import { UseNotFoundIconStyle } from "./style";

const NotFoundIcon = () => {
  return (
    <IconWrapperSC>
      <svg
        width="82"
        height="82"
        viewBox="0 0 82 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M70.0146 2.21509H11.9834C6.64176 2.21509 2.31152 6.54533 2.31152 11.887V69.9182C2.31152 70.4603 2.35611 70.9917 2.44175 71.5092C3.20061 76.0936 7.18373 79.59 11.9834 79.59H70.0146C75.3564 79.59 79.6865 75.2599 79.6865 69.9182V51.4937V11.887C79.6865 6.54533 75.3564 2.21509 70.0146 2.21509Z"
          stroke="#3E3E3E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.1631 26.3948C36.1631 31.7365 31.833 36.0666 26.4912 36.0666C21.1496 36.0666 16.8193 31.7365 16.8193 26.3948C16.8193 21.0531 21.1496 16.7229 26.4912 16.7229C31.833 16.7229 36.1631 21.0531 36.1631 26.3948Z"
          stroke="#3E3E3E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.44336 71.5092L20.92 53.0326C24.2086 50.1552 29.0181 49.8404 32.6538 52.2642L35.1163 53.9055C38.6204 56.2417 43.2344 56.0415 46.5233 53.4108L59.3293 43.1654C62.5936 40.5539 67.1403 40.3494 70.6101 42.5875C70.9946 42.8355 71.3369 43.1426 71.6605 43.4666L79.6881 51.4938"
          stroke="#3E3E3E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconWrapperSC>
  );
};

const { IconWrapperSC } = UseNotFoundIconStyle();

export default React.memo(NotFoundIcon);
