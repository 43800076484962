import styled from "styled-components";
import { mediaCSS } from "../../../../theme/CSSPattern";

const TextAreaSC = styled("textarea")<{ isError?: boolean }>`
  margin-bottom: 27px;
  background: ${({ theme }) => theme.textAreaColor};
  border-radius: 15px;
  width: 100%;
  height: 379px;
  padding: 20px 27px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  resize: none;
  border: ${({ theme, isError }) =>
    isError ? `2px solid ${theme.error}` : "none"};

  &::placeholder {
    opacity: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: ${({ theme, isError }) =>
      isError ? theme.error : theme.placeholder};
    ${mediaCSS(600)} {
      font-size: 14px;
      line-height: 19px;
    }
  }
  ${mediaCSS(600)} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const useTextAreaStyle = () => ({
  TextAreaSC,
});
