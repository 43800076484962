import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

export const TitleSC = styled("h2")<{ textAlign: string }>`
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: ${({ textAlign }) => textAlign};
  ${mediaCSS(1200)} {
    font-size: 30px;
  }
  ${mediaCSS(600)} {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
`;

export const useTitleStyle = () => ({
  TitleSC,
});
