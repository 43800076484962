export const themeObj = {
  error: "#F04A4A",
  placeholder: "#ACACAC",
  textAreaColor: "#F1F1F1",
  violetColor: "#4C3348",
  mainBg: "#2D2E40",
  buttonColor:
    "linear-gradient(90.42deg, #FC6D11 15.29%, rgba(252, 109, 17, 0) 76.43%), #EB3F1B",
  aboutCompany:
    "linear-gradient(90.55deg, #86112E 17.08%, rgba(134, 17, 46, 0) 74.22%), #4C3348",
};
