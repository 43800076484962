import React from "react";
import { IProductCardsProps } from "../../../../types/types";
import NotFoundIcon from "./NotFoundIcon/NotFoundIcon";
import { useProductCardStyle } from "./style";
import { useProductCard } from "./useProductCard";
import { Link } from "react-router-dom";

const ProductCard = ({
  price,
  title,
  vendor_code,
  description,
  image,
  id,
  style,
}: IProductCardsProps) => {
  const { isTable } = useProductCard();

  return (
    <Link to={`cards/${id}`}>
      <CardWrapperSC style={style}>
        {image ? (
          <ImgSC src={image} />
        ) : (
          <PlaceholderWrapperSC>
            <NotFoundIcon />
          </PlaceholderWrapperSC>
        )}
        <RightWrapperSC>
          <PriceSC>{price} ₽</PriceSC>
          {!isTable && <NumberSC>{vendor_code}</NumberSC>}
          <PartNameSC>{title}</PartNameSC>
          <DescriptionSC>{description}</DescriptionSC>
          {isTable && <NumberSC>{vendor_code}</NumberSC>}
        </RightWrapperSC>
      </CardWrapperSC>
    </Link>
  );
};

const {
  ImgSC,
  PriceSC,
  NumberSC,
  PartNameSC,
  DescriptionSC,
  CardWrapperSC,
  RightWrapperSC,
  PlaceholderWrapperSC,
} = useProductCardStyle();

export default React.memo(ProductCard);
