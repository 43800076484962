import React from "react";
import Header from "components/Header/Header";
import AboutCompany from "components/AboutCompany/AboutCompany";

interface IMainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: IMainLayoutProps) => {
  return (
    <>
      <>
        <Header />
        <main>
          <AboutCompany />
          {children}
        </main>
      </>
    </>
  );
};

export default React.memo(MainLayout);
