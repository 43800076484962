import styled from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

export const ButtonUI = styled("button")`
  cursor: pointer;
  background: ${({ theme }) => theme.buttonColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-bottom: 48px;
  height: 60px;
  ${mediaCSS(600)} {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }
`;
