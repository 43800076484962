import styled from "styled-components";
import { mediaCSS } from "../../../theme/CSSPattern";
const TitleWrapperSC = styled("div")`
  margin-bottom: 35px;
`;

const CardListSC = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const ListWrapperSC = styled("section")`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 75%;
  margin-left: 85px;
  ${mediaCSS(1200)} {
    margin-left: 35px;
  }
  ${mediaCSS(1080)} {
    margin-left: 20px;
  }
  ${mediaCSS(996)} {
    margin-left: 0px;
    width: 100%;
  }
`;

export const useProductListStyle = () => ({
  CardListSC,
  ListWrapperSC,
  TitleWrapperSC,
});
