import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTablet } from "../../../hooks/useMediaQuery";
import { axiosGears } from "../../../lib/http";
import { IProductCardsProps } from "../../../types/types";

export const useProductList = () => {
  const [cards, setCards] = useState<IProductCardsProps[]>([]);
  const isTable = useTablet();
  const [loadingCard, setLimit] = useState<number>(100);
  // const [isEndCards, setIsEndCards] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const rowHeight = isTable ? 293 : 278;
  const marginBottom = isTable ? 4 : 40;
  const difference = isTable ? 2 : 20;
  const limit = loadingCard > cards.length ? cards.length : loadingCard;
  const isEndCards = loadingCard > cards.length;
  const fetchData = async () => {
    setLoading(true);
    const res = await axiosGears.get("/post", {});
    setCards(res.data.posts);
    setLoading(false);
  };
  const loader = useRef(null);

  const handleClickMobile = () => {
    if (!(loadingCard > cards.length)) {
      const scrolled = window.scrollY;

      setLimit((prev) => prev + 100);
      window.scrollTo(0, scrolled - 500);
    }
  };
  const handleObserver = useCallback(
    (entries: any[]) => {
      const target = entries[0];
      if (target.isIntersecting && !loading) {
        setLimit((prev) => {
          return prev + 100;
        });
      }
    },
    [loading],
  );
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver, loadingCard]);
  useEffect(() => {
    fetchData();
  }, []);
  return {
    handleClickMobile,
    loadingCard,
    marginBottom,
    isEndCards,
    difference,
    rowHeight,
    loading,
    limit,
    isTable,
    cards,
    loader,
  };
};
