import React from "react";
import TitleUI from "UI/TitleUI/TitleUI";
import { useCardFullStyle } from "./style";
import { useCardFull } from "./useCardFull";

const CardFull = () => {
  const { isTable, cards, isLoading } = useCardFull();
  // const { title, description, price, vendor_code, image } = cards;
  return (
    <>
      {isLoading && (
        <ContentWrapperSC>
          <TitleWrapperSC>
            <TitleUI textAlign="left" text="ПРОДУКЦИЯ" />
          </TitleWrapperSC>
          <WrapperSC>
            <ImgSC src={cards.image} alt="card product" />
            <RightWrapperSC>
              <PriceSC>{cards.price} ₽</PriceSC>
              {!isTable && <VendorCodeSC>{cards.vendor_code}</VendorCodeSC>}

              <PartNameSC>{cards.title}</PartNameSC>
              <DescriptionSC>{cards.description}</DescriptionSC>
              {isTable && <VendorCodeSC>{cards.vendor_code}</VendorCodeSC>}
            </RightWrapperSC>
          </WrapperSC>
        </ContentWrapperSC>
      )}
    </>
  );
};

const {
  ImgSC,
  PriceSC,
  PartNameSC,
  WrapperSC,
  VendorCodeSC,
  DescriptionSC,
  TitleWrapperSC,
  RightWrapperSC,
  ContentWrapperSC,
} = useCardFullStyle();
export default React.memo(CardFull);
