import React from "react";
import { useAboutCompanyStyle } from "./style";

const AboutCompany = () => {
  return (
    <BGSC>
      <WrapperSC>
        <TitleSC>О КОМПАНИИ</TitleSC>
        <DescrSC>
          Продаём запчасти для спецтехники. Огромный выбор оригинальных
          запчастей по доступным ценам. Любые запчасти для всех марок
          спецтехники. Покупать у нас – надёжно. За качество мы отвечаем!
        </DescrSC>
      </WrapperSC>
    </BGSC>
  );
};

const { BGSC, TitleSC, DescrSC, WrapperSC } = useAboutCompanyStyle();

export default React.memo(AboutCompany);
