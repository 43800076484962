import React from "react";
import { Controller } from "react-hook-form";
import { useTextAreaStyle } from "./style";
import { useTextArea } from "./useTextArea";

const TextArea = () => {
  const { control } = useTextArea();
  return (
    <Controller
      name="aboutUs"
      control={control}
      rules={{}}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <TextAreaSC
            value={value}
            isError={!!error}
            onChange={onChange}
            placeholder={"Введите сообщение"}
          />
        );
      }}
    />
  );
};

const { TextAreaSC } = useTextAreaStyle();

export default React.memo(TextArea);
