import styled from "styled-components";
import { mediaCSS } from "../../../theme/CSSPattern";
import { TitleSC } from "../../../UI/TitleUI/style";

const FormSC = styled("form")`
  max-width: 333px;
  width: 100%;
  //   position: sticky;
  display: flex;
  flex-direction: column;
  //   top: 0;
  ${mediaCSS(1200)} {
    max-width: 295px;
  }
  ${mediaCSS(996)} {
    max-width: 100%;
  }
`;
const FormTitleWrapperSC = styled("div")`
  margin-bottom: 35px;
  ${mediaCSS(600)} {
    margin-bottom: 19px;
  }
`;

//   height: 60px;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 27px;

const ButtonSC = styled("button")`
  cursor: pointer;
  background: ${({ theme }) => theme.buttonColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-bottom: 48px;
  height: 60px;
  ${mediaCSS(600)} {
    height: 40px;
    font-size: 16px;
    line-height: 22px;
  }
`;

const RequisitesSC = styled("div")`
  background: #4c3348;
  border-radius: 15px;

  padding: 32px 35px 51px 36px;
  ${mediaCSS(600)} {
    padding: 16px 40px 25px 22px;
  }
`;

const RequisitesTitleWrapperSC = styled("div")`
  margin-bottom: 18px;
  ${mediaCSS(600)} {
    margin-bottom: 11px;
  }
`;

const DescriptionSC = styled("p")`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  ${mediaCSS(600)} {
    font-size: 14px;
    line-height: 19px;
  }
`;

const FormTitleSC = styled(TitleSC)`
  ${mediaCSS(600)} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const ErrorMessageSC = styled("p")`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #f04a4a;
  margin-bottom: 31px;
  padding-left: 17px;
`;

export const useFormFeedbackStyle = () => ({
  FormSC,
  ButtonSC,
  FormTitleSC,
  RequisitesSC,
  DescriptionSC,
  ErrorMessageSC,
  FormTitleWrapperSC,
  RequisitesTitleWrapperSC,
});
