import "./styles/globals.css";

import Theme from "./theme/Theme";

import { Route, Routes, useLocation } from "react-router-dom";
import CardPage from "pages/CardPage";
import HomePage from "pages/HomePage";

function App() {
  const a = useLocation();
  return (
    <Theme>
      <Routes>
        <Route path="/cards/:id" element={<CardPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Theme>
  );
}

export default App;
