import React from "react";
import { Controller } from "react-hook-form";
import InputUI from "../../../../UI/InputUI/InputUI";
import { useEmailInputStyle } from "./style";
import { useMailInput } from "./useEmailInput";

const TextArea = () => {
  const { control, emailReg } = useMailInput();
  return (
    <Controller
      name="email"
      control={control}
      defaultValue={""}
      rules={{ required: true, pattern: emailReg }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            styleComponents={{ InputStyle: InputEmail }}
            inputProps={{
              type: "email",
              inputMode: "email",
              placeholder: "E-mail для обратной связи",
            }}
          />
        );
      }}
    />
  );
};

const { InputEmail } = useEmailInputStyle();

export default React.memo(TextArea);
