import styled, { css } from "styled-components";
import { mediaCSS } from "../../theme/CSSPattern";

const CSSSticky = css`
  position: sticky;
  top: 0;
`;

const HeaderContainerSC = styled("div")`
  //   margin: 28px auto 0 auto;
  top: 0;
  ${CSSSticky}
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.violetColor};
  z-index: 9999;
  height: 65px;
  justify-content: space-between;
`;

const HeaderSC = styled("header")`
  ${CSSSticky}
  display: flex;
  top: 0;
  z-index: 9999;
`;

const WrapperSC = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  ${mediaCSS(996)} {
    justify-content: center;
  }
  ${mediaCSS(380)} {
    padding-left: 50px;
  }
`;

const NavSC = styled("nav")`
  max-width: 188px;
  width: 100%;
`;

const UlNavSC = styled("ul")`
  display: flex;
  justify-content: space-between;
  ${mediaCSS(996)} {
    flex-direction: column;
  }
`;

const LiSC = styled("li")`
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  ${mediaCSS(996)} {
    margin-bottom: 15px;
    color: #000000;
  }
`;
const WrapperTitleSC = styled("div")`
  ${mediaCSS(380)} {
  }
`;
const ButtonMobileSC = styled("div")<{ isOpen: boolean }>`
  transition: all 0.5s;
  cursor: pointer;
  width: 36px;
  height: 36px;
  left: 12px;
  top: 12px;
  border-radius: 5px;
  position: fixed;
  background: ${({ isOpen }) => (isOpen ? "#4C3348" : "#fff")};
  z-index: 9999;
  & > span {
    top: 15px;
  }
  & > span,
  span::before,
  span::after {
    left: 50%;
    transform: translateX(-50%);
    display: block;
    position: relative;
    width: 16px;
    height: 2px;
    background-color: ${({ isOpen }) => (isOpen ? "#ffffff" : "#4C3348")};
  }
  & > span::before {
    content: "";
    top: -6px;
  }
  & > span::after {
    content: "";
    top: 5px;
  }
`;

const SidebarSC = styled("div")<{ isOpen: boolean }>`
  transition: all 0.5s;
  position: fixed;
  width: 221px;
  height: 166px;
  background: #ffffff;
  border-radius: 0px 0px 8px 0px;
  left: ${({ isOpen }) => (isOpen ? 0 : -222)}px;
  top: 0;
  padding: 16px 0 10px 22px;
`;
const MenuSC = styled("h3")`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  margin-left: 36px;
  margin-bottom: 31px;
`;
const ContactOverlaySC = styled("div")`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ContactWrapperSC = styled("div")<{ isShow: boolean }>`
  transition: all 0.5s;
  position: fixed;
  top: ${({ isShow }) => (isShow ? "72px" : "-300px")};
  right: 192px;
  max-width: 826px;
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 15px;
  padding: 16px 19px 24px 29px;
  ${mediaCSS(600)} {
    position: static;
  }
`;
const TextSC = styled("p")`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`;
export const useHeaderStyle = () => ({
  LiSC,
  NavSC,
  MenuSC,
  UlNavSC,
  HeaderSC,
  WrapperSC,
  SidebarSC,
  TextSC,
  ContactWrapperSC,
  ContactOverlaySC,
  WrapperTitleSC,
  ButtonMobileSC,
  HeaderContainerSC,
});
