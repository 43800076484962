import React, { ComponentType } from "react";
import TitleUI from "../../../UI/TitleUI/TitleUI";
import { useProductListStyle } from "./style";
import { useProductList } from "./useProductList";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid as Grid } from "react-window";
import Row from "./Row/Row";
import { ButtonUI } from "../../../UI/ButtonUI/style";
import LoadIcon from "components/LoadIcon/LoadIcon";

const ProductList = () => {
  const {
    handleClickMobile,
    marginBottom,
    rowHeight,
    isEndCards,
    loading,
    difference,
    limit,
    isTable,
    cards,
    loader,
  } = useProductList();

  return (
    <ListWrapperSC>
      <>
        <TitleWrapperSC>
          <TitleUI textAlign="left" text="Продукция" />
        </TitleWrapperSC>
        {!loading && (
          <AutoSizer
            style={{
              height: `${
                (limit / 2) * rowHeight + (marginBottom - difference) * limit
              }px`,
              position: "sticky",
              overflow: "visible",
            }}>
            {({ height, width }) => {
              return (
                <Grid
                  style={{ overflow: "visible" }}
                  itemData={cards}
                  height={
                    (limit / 2) * rowHeight +
                    (marginBottom - difference) * limit
                  }
                  width={width}
                  rowHeight={rowHeight + marginBottom}
                  columnCount={2}
                  columnWidth={width / 2}
                  rowCount={limit > 1000 ? 1000 / 2 : limit / 2}>
                  {Row}
                </Grid>
              );
            }}
          </AutoSizer>
        )}
        {loading && <LoadIcon />}
        {!isTable && <div ref={loader} />}
        <CardListSC></CardListSC>
        {isTable && (
          <ButtonUI onClick={handleClickMobile}>Показать ещё</ButtonUI>
        )}
        <CardListSC></CardListSC>
      </>
    </ListWrapperSC>
  );
};

const { CardListSC, ListWrapperSC, TitleWrapperSC } = useProductListStyle();

export default React.memo(ProductList);
