import React, { ComponentType, CSSProperties } from "react";
import { GridChildComponentProps } from "react-window";
import { useTablet } from "../../../../hooks/useMediaQuery";
import { IAutopart } from "../../../../lib/modals/IAutopart";
import ProductCard from "../ProductCard/ProductCard";

const Row: ComponentType<GridChildComponentProps<IAutopart[]>> = ({
  data: autoPartArr,
  rowIndex,
  columnIndex,
  style,
}) => {
  const currentAutoPartItem =
    autoPartArr[rowIndex * 2 + columnIndex] ||
    ({ title: "", price: "", image: "", description: "", id: 1 } as IAutopart);
  const { title, description, price, image, vendor_code, id } =
    currentAutoPartItem;
  const isTable = useTablet();
  return (
    <div
      style={{
        ...style,
      }}>
      <ProductCard
        title={title}
        description={description}
        vendor_code={vendor_code}
        price={price}
        image={image}
        id={id}
        style={{}}
      />
    </div>
  );
};

export default React.memo(Row);
