import React from "react";
import { Controller } from "react-hook-form";
import InputUI from "../../../../UI/InputUI/InputUI";
import { usePhoneInputStyle } from "./style";
import { usePhoneInput } from "./usePhoneInput";

const TextArea = () => {
  const { control } = usePhoneInput();
  return (
    <Controller
      name="phone"
      control={control}
      rules={{ required: true, minLength: 11, maxLength: 11 }}
      defaultValue={""}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <InputUI
            value={value}
            onChange={onChange}
            error={!!error}
            inputProps={{
              inputMode: "tel",
              type: "tel",
              placeholder: "Номер телефона",
              minLength: 11,
              maxLength: 11,
            }}
            styleComponents={{
              InputStyle: InputPhone,
            }}
          />
        );
      }}
    />
  );
};

const { InputPhone } = usePhoneInputStyle();

export default React.memo(TextArea);
