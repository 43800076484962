import styled from "styled-components";
import { InputSC } from "../../../../UI/InputUI/style";

const InputPhone = styled(InputSC)<{ isError: boolean }>`
  margin-bottom: 27px;
  //   border: ${({ isError }) => (isError ? "1px solid red" : "none")};
`;

export const usePhoneInputStyle = () => ({
  InputPhone,
});
