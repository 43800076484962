import React from "react";
import { Link } from "react-router-dom";
import TitleUI from "../../UI/TitleUI/TitleUI";
import AppContainer from "../anyPage/AppContainer/AppContainer";
import { useHeaderStyle } from "./style";
import { useHeader } from "./useHeader";
import ReactDOM from "react-dom";
import { useMobile, useTablet } from "../../hooks/useMediaQuery";

const Header = () => {
  const isMobile = useMobile();
  const {
    isTable,
    ScrollTo,
    pathname,
    contactRef,
    menuButton,
    menuMobile,
    contactButton,
    isShowContact,
    handleContact,
    IsOpenSidebar,
  } = useHeader();
  return (
    <HeaderSC>
      <HeaderContainerSC>
        <AppContainer maxWidth={1696}>
          <WrapperSC>
            {isTable && (
              <>
                <ButtonMobileSC ref={menuButton} isOpen={IsOpenSidebar}>
                  <span />
                </ButtonMobileSC>
                <SidebarSC isOpen={IsOpenSidebar} ref={menuMobile}>
                  <MenuSC>Меню</MenuSC>
                  <NavSC>
                    <UlNavSC>
                      <LiSC onClick={handleContact} ref={contactButton}>
                        <a>Контакты</a>
                      </LiSC>
                      {pathname === "/" ? (
                        <LiSC onClick={ScrollTo()}>
                          <a>Каталог</a>
                        </LiSC>
                      ) : (
                        <LiSC>
                          <Link to={"/"}>Каталог</Link>
                        </LiSC>
                      )}
                    </UlNavSC>
                  </NavSC>
                </SidebarSC>
              </>
            )}
            <WrapperTitleSC>
              {/* <TitleUI
                text="шестерёнки онлайн"
                textAlign={isTable ? "center" : "left"}
              /> */}
            </WrapperTitleSC>

            {!isTable && (
              <NavSC>
                <UlNavSC>
                  <LiSC onClick={handleContact} ref={contactButton}>
                    <a>Контакты</a>
                  </LiSC>
                  {pathname === "/" ? (
                    <LiSC onClick={ScrollTo()}>
                      <a>Каталог</a>
                    </LiSC>
                  ) : (
                    <LiSC>
                      <Link to={"/"}>Каталог</Link>
                    </LiSC>
                  )}
                </UlNavSC>
              </NavSC>
            )}
            {!isMobile && (
              <ContactWrapperSC isShow={isShowContact} ref={contactRef}>
                <TextSC>
                  E-mail zaichev1301@mail.ru
                  <br />
                  Телефон +8 (982) 274-08-30
                </TextSC>
              </ContactWrapperSC>
            )}
            {isMobile && (
              <>
                {isShowContact &&
                  ReactDOM.createPortal(
                    <ContactOverlaySC onClick={handleContact}>
                      <ContactWrapperSC isShow={isShowContact}>
                        <TextSC>
                          E-mail zaichev1301@mail.ru
                          <br />
                          Телефон +8 (982) 274-08-30
                        </TextSC>
                      </ContactWrapperSC>
                    </ContactOverlaySC>,
                    document.body,
                  )}
              </>
            )}
          </WrapperSC>
        </AppContainer>
      </HeaderContainerSC>
    </HeaderSC>
  );
};

const {
  LiSC,
  NavSC,
  MenuSC,
  UlNavSC,
  HeaderSC,
  WrapperSC,
  SidebarSC,
  TextSC,
  ContactWrapperSC,
  ContactOverlaySC,
  WrapperTitleSC,
  ButtonMobileSC,
  HeaderContainerSC,
} = useHeaderStyle();

export default React.memo(Header);
