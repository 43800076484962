import styled from "styled-components";
import { mediaCSS } from "theme/CSSPattern";

const ContentWrapperSC = styled("div")`
  padding-left: 85px;
  margin-bottom: 20px;
  ${mediaCSS(1300)} {
    padding-left: 15px;
  }
  ${mediaCSS(996)} {
    padding-left: 0px;
  }
`;

const TitleWrapperSC = styled("div")`
  margin-bottom: 35px;
`;

const WrapperSC = styled("div")`
  padding: 35px 106px 66px 55px;
  max-width: 1313px;
  width: 94%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  background: #000000;
  height: fit-content;
  ${mediaCSS(1400)} {
    padding: 15px 60px 15px 55px;
    width: 99%;
  }
  ${mediaCSS(996)} {
    width: 100%;
    padding: 0px;
    flex-direction: column;
  }
`;

const PriceSC = styled("h3")`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  margin-bottom: 9px;
  ${mediaCSS(996)} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ImgSC = styled("img")`
  width: 270px;
  height: 278px;
  object-fit: cover;
  ${mediaCSS(1200)} {
    width: 180px;
    height: 188px;
  }
  ${mediaCSS(996)} {
    margin-bottom: 8px;
    width: 100%;
    height: 115px;
  }
`;

const RightWrapperSC = styled("div")`
  margin-left: 44px;
  ${mediaCSS(1200)} {
    margin-left: 11px;
  }
  ${mediaCSS(996)} {
    padding: 0 16px 15px 16px;
  }
`;

const VendorCodeSC = styled("h4")`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #8c8c8c;
  margin-bottom: 9px;
  ${mediaCSS(996)} {
    font-size: 14px;
    line-height: 19px;
  }
`;

const PartNameSC = styled("h2")`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 15px;
  ${mediaCSS(996)} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const DescriptionSC = styled("p")`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  ${mediaCSS(996)} {
    margin-bottom: 9px;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const useCardFullStyle = () => ({
  ImgSC,
  PriceSC,
  PartNameSC,
  WrapperSC,
  VendorCodeSC,
  DescriptionSC,
  TitleWrapperSC,
  RightWrapperSC,
  ContentWrapperSC,
});
