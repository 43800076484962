import { useEffect, useState } from "react";
import { useTablet } from "hooks/useMediaQuery";
import { IProductCardsProps } from "types/types";
import axios from "axios";
import { axiosGears } from "lib/http";

export const useCardFull = () => {
  const isTable = useTablet();
  const [cards, setCards] = useState<IProductCardsProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // console.log(window.location.pathname.indexOf("/"));
    // console.log(
    //   window.location.pathname.slice(window.location.pathname.indexOf("/")),
    // );
    if (window) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const pageID = JSON.parse(JSON.stringify(window.location.pathname));
      axiosGears
        .get<{ post_item: IProductCardsProps }>(
          `/post/${pageID.split("/").reverse()[0]}`,
        )
        .then((r) => {
          setCards(r.data.post_item);
          setIsLoading(true);
        });
    }
  }, []);

  return {
    cards,
    isTable,
    isLoading,
  };
};
