import React from "react";
import { useLoadIconStyle } from "./style";

const LoadIcon = () => {
  return (
    <LoadIconWrapperSC>
      <svg
        width="50"
        height="53"
        viewBox="0 0 50 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M47.0666 17.7582C41.2531 12.5415 35.8695 6.63519 27.7207 5.48881C22.8943 4.80977 17.9777 5.71765 13.7121 8.07556C9.44644 10.4335 6.06268 14.1138 4.07069 18.5619C2.07868 23.0099 1.58635 27.9849 2.66787 32.7371C3.74938 37.4893 6.34615 41.7614 10.0669 44.9095C13.7877 48.0577 18.4309 49.9115 23.2969 50.1915C28.1628 50.4715 32.9881 49.1627 37.0454 46.4621C41.103 43.7616 44.1727 39.8157 45.7923 35.2191"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.0665 2.77179V17.7582H32.0801"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </LoadIconWrapperSC>
  );
};

const { LoadIconWrapperSC } = useLoadIconStyle();

export default React.memo(LoadIcon);
