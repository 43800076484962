import React, { useRef } from "react";
import emailjs from "emailjs-com";

export const useEmailJS = () => {
  const serviceId = "service_oinh39p";
  const templateId = "template_n14tphg";
  const publickKey = "f1GwrOJ84DC7x7Jzp";
  const form = useRef();

  const onFetchMail = async (formValue: any) => {
    return await emailjs
      .send(serviceId, templateId, formValue, publickKey)
      .then(
        (result) => {},
        (error) => {
          return error;
        },
      );
  };
  return { onFetchMail };
};
