import React from "react";
import { DefaultTheme, StyledComponent } from "styled-components";
import { useTitleStyle } from "./style";

interface ITitleUI {
  text: string;
  textAlign?: "center" | "left" | "right";
  styleComponents?: {
    TitleStyle?: StyledComponent<
      "h2",
      DefaultTheme,
      {
        textAlign: string;
      },
      never
    >;
  };
}

const TitleUI = ({
  text,
  textAlign = "center",
  styleComponents = {},
}: ITitleUI) => {
  const { TitleStyle } = styleComponents;

  const Title = TitleStyle || TitleSC;

  return <Title textAlign={textAlign}>{text}</Title>;
};

const { TitleSC } = useTitleStyle();

export default React.memo(TitleUI);
