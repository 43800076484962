import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTablet } from "../../hooks/useMediaQuery";

export const useHeader = () => {
  const { pathname } = useLocation();
  const isTable = useTablet();
  const menuButton = useRef<HTMLDivElement>(null);
  const menuMobile = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const contactButton = useRef<HTMLLIElement>(null);
  const [isShowContact, setIsShowContact] = useState(false);
  const [IsOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const ScrollTo = (scrollNumber = 290) => {
    return () => {
      window.scrollTo({ top: scrollNumber, behavior: "smooth" });
    };
  };
  useEffect(() => {
    const onClick = (e) => {
      console.log(!contactButton);
      if (menuButton.current) {
        if (menuButton.current.contains(e.target)) {
          setIsOpenSidebar((prev) => !prev);
          return;
        }
      }
      if (menuMobile.current) {
        if (menuMobile.current.contains(e.target)) {
          return;
        }
      }

      if (
        !contactButton.current.contains(e.target) &&
        !contactRef.current.contains(e.target)
      ) {
        setIsShowContact(false);
      }
      setIsOpenSidebar(false);
    };

    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);
  // const handleClickMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //   setIsOpenSidebar((prev) => !prev);
  // };
  const handleContact = () => {
    setIsShowContact((prev) => !prev);
  };
  return {
    isTable,
    ScrollTo,
    pathname,
    contactRef,
    menuButton,
    menuMobile,
    contactButton,
    isShowContact,
    handleContact,
    IsOpenSidebar,
    // handleClickMenu,
  };
};
